import React, { useState, useEffect } from "react";
import "../Css/FriendTab.css";

export const FriendTab = props => {
  const [friend, setFriend] = useState({});
  const [openConvos, setOpenConvos] = useState([]);
  const [renderConvos, setRenderConvos] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (props.fullFriendInfo) {
      setFriend(props.fullFriendInfo);
      setCount(count + 1);
    }
  }, [props.fullFriendInfo]);

  useEffect(() => {
    if (count === 1) {
      setOpenConvos([friend]);
    } else if (count >= 2) {
      setOpenConvos(prevOpenConvos => {
        const newArray = prevOpenConvos.filter(function(friendo) {
          return friendo !== friend;
        });
        const updateConvoList = [friend, ...newArray];
        return updateConvoList;
      });
    } else {
      setOpenConvos([]);
    }
  }, [friend]);

  useEffect(() => {
    setRenderConvos(true);
  }, [openConvos]);

  if (openConvos.length > 0) {
    return (
      <div className="tab-space">
        {renderConvos && props.auth &&
          openConvos.map(openConvo => {
            return (
              <div key={openConvo.id} className="friend-tab">
                {openConvo.name}
              </div>
            );
          })}
      </div>
    );
  } else return <div className="tab-space" />;
};
