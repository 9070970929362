import React, { useState, useEffect } from "react";
import "./App.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as queries from "./graphql/queries";
import awsmobile from "./aws-exports";
import NavBar from "./Components/NavBar";
import { FriendBox } from "./Components/FriendBox";
import { TypeBox } from "./Components/TypeBox";
import { ChatBox } from "./Components/ChatBox";
import { SignUp } from "./Components/SignUp";
import { SignIn } from "./Components/SignIn";
import useInterval from "./useInterval";
import { FriendTab } from "./Components/FriendTab";

Amplify.configure(awsmobile);
var bcrypt = require("bcryptjs");

// OuterMost Layer for the App Management
const App = () => {
  const [signUp, setSignUp] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState([]);
  const [username, setUsername] = useState("");
  const [loginMsg, setLoginMsg] = useState("");
  const [loginMsgTimer, setLoginMsgTimer] = useState(0);
  const [selectedUser, setselectedUser] = useState(null);
  const [selectedConvo, setSelectedConvo] = useState(null);
  const [friendShow, setFriendShow] = useState(false);
  const [fullFriendInfo, setFullFriendInfo] = useState(null);
  const [leftStyle, setLeftStyle] = useState("wide-left");
  const [rightStyle, setRightStyle] = useState("thin-right");

  useEffect(() => {
    if (localStorage.getItem("userName") && localStorage.getItem("userPw")) {
      let user = { name: localStorage.getItem("userName") };
      let pw = localStorage.getItem("userPw");
      setUsername(localStorage.getItem("userName"));
      authUser(user, pw);
    }
  }, []);

  useInterval(() => {
    if (loginMsg && loginMsgTimer >= 2) {
      setLoginMsg(false);
    } else if (loginMsg && loginMsgTimer < 2) {
      setLoginMsgTimer(loginMsgTimer + 1);
    }
  }, 1000);

  useEffect(() => {
    if (friendShow) {
      setLeftStyle("friend-hide");
      setRightStyle("friend-show");
    } else if (!friendShow) {
      setLeftStyle("");
      setRightStyle("");
    }
  }, [friendShow]);

  useEffect(() => {
    setFriendShow(false);
  }, [selectedUser]);

  // Selected User will be placed in state
  const setConvo = userInfo => {
    setselectedUser(userInfo.id);
    setFullFriendInfo(userInfo);
  };

  // Manages Login/SignUp Form Visibility
  const handleUserSignUp = () => {
    setSignUp(!signUp);
  };
  const handleUserSignIn = () => {
    setSignIn(!signIn);
  };

  // ::::::::::::::::: Set Selected Convo ::::::
  const convoSelection = convoId => {
    setSelectedConvo(convoId);
  };

  // :::::::::::::::: Show Friend list ::::::::::

  const showFriendsOnPhone = () => {
    setFriendShow(!friendShow);
  };

  //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  //::::::::: AUTH & SIGN IN :::::::::::::::::::::::::::::::::::::::::::::::
  //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const authUser = (user, pwd) => {
    const searchUser = async () => {
      // AUTHORIZING USER BELOW
      const findUser = await API.graphql(
        // Query/Filter DB for USER
        graphqlOperation(queries.listUsers, {
          limit: 200,
          filter: { name: { eq: user.name } }
        })
      );
      // dbUserInfo is assigned an object of user info containing, name, password, etc..
      const dbUserInfo = findUser.data.listUsers.items[0];
      // If a user is found that matches the sign-in entered user name...
      if (dbUserInfo) {
        // If the localstorage pwd matches the dbUserInfo.password... Sign in the user
        if (pwd === dbUserInfo.password) {
          userSignIn(dbUserInfo);
        } else {
          bcrypt.compare(pwd, dbUserInfo.password).then(isMatch => {
            // Bcrypt Compares user entered password with hashed Backend Password
            if (isMatch && dbUserInfo.name === user.name) {
              // If pw & username match - call userSignIn
              userSignIn(dbUserInfo);
            } else if (!isMatch && dbUserInfo.name === user.name) {
              // If password incorrect, notify user!
              setLoginMsg("Password INCORRECT!");
            } else if (!isMatch && dbUserInfo.name !== user.name) {
              setLoginMsg("User/Password not found!");
            } else {
              // If Everything is incorrect, notify user!
              setLoginMsg("User/Password Incorrect");
            }
          });
        }
        // If the user was not found - notify of bad username
      } else if (!dbUserInfo) {
        setLoginMsg("Username not Found!");
      }
    };
    searchUser(); // Calls the above method ^^^
  };

  const userSignIn = dbUserInfo => {
    // SignIn Sequence
    setUser(dbUserInfo); // Sets UserInfo in state
    setAuth(true); // App is authd -- render User's page
    setSignUp(false); // Get rid of SignUp Form
    setSignIn(false); // Get rid of SignIn Form
    setLoginMsg("Sign In Successful"); // Notify User of Successful Login
    setRightStyle("");
    setLeftStyle("");
    setUsername(localStorage.getItem("userName"));
    localStorage.setItem("userName", dbUserInfo.name); // Set LocalStorage for Relogin
    localStorage.setItem("userPw", dbUserInfo.password); // Set LocalStorage for Relogin
  };

  const logout = () => {
    setUser([]); // Sets UserInfo in state
    setAuth(false); // App is authd -- render User's page
    setSignUp(false); // Get rid of SignUp Form
    setSignIn(false); // Get rid of SignIn Form
    setRightStyle("thin-right");
    setLeftStyle("wide-left");
    setUsername("");
    setLoginMsg("Sign Out Successful"); // Notify User of Successful Login
    localStorage.setItem("userName", ""); // Set LocalStorage for Relogin
    localStorage.setItem("userPw", ""); // Set LocalStorage for Relogin
  };

  //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  //:::::::::: Render App Below ::::::::::::::::::::::::::::::::::::::::
  //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <div className="App">
      <div className="navbar-outer">
        <NavBar
          handleUserSignUp={handleUserSignUp}
          handleUserSignIn={handleUserSignIn}
          render={signUp}
          renderIn={signIn}
          loginMsg={loginMsg}
          auth={auth}
          user={user}
          logout={logout}
          showFriendsOnPhone={showFriendsOnPhone}
        />
      </div>
      <FriendTab fullFriendInfo={fullFriendInfo} />
      <SignUp
        authUser={authUser}
        render={signUp}
        handleUserSignUp={handleUserSignUp}
      />
      <SignIn
        authUser={authUser}
        render={signIn}
        handleUserSignIn={handleUserSignIn}
      />
      <div className="mid-box-main">
        <div className={"left-section " + leftStyle}>
          <ChatBox
            auth={auth}
            user={user}
            selectedUser={selectedUser}
            convoSelection={convoSelection}
            convo={selectedConvo}
          />
          <TypeBox convo={selectedConvo} user={user} auth={auth} />
        </div>
        <div className={"right-tall-bar " + rightStyle}>
          <FriendBox
            setConvo={setConvo}
            auth={auth}
            selectedUser={selectedUser}
            friendShow={friendShow}
            username={username}
          />
        </div>
      </div>
    </div>
  );
};
export default App;
