import React, { useState, useEffect } from "react";
import "../Css/SendBar.css";

export const SendBar = props => {
  const [sendClass, setSendClass] = useState("");

  useEffect(() => {
    if (props.message !== "") {
      setSendClass("show");
    } else {
      setSendClass("");
    }
  }, [props]);

  const keyPress = e => {
    if (e.key === "Enter") {
      props.send();
    }
  };

  return (
    <div className="sendBar">
      <i
        className={"far fa-paper-plane send-btn " + sendClass}
        onClick={props.send}
        onKeyPress={keyPress}
      />
    </div>
  );
};
