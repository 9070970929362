// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "chatterson-chatapp-20190410070336-hostingbucket-chattyyea",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://chatterson-chatapp-20190410070336-hostingbucket-chattyyea.s3-website-us-west-2.amazonaws.com",
    "aws_appsync_graphqlEndpoint": "https://jlhvewrptvd3xbkipydlbkoovu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-pngceedqqbc33l5ldtx2tgbyv4",
    "aws_cognito_identity_pool_id": "us-west-2:6ec534c9-981a-40df-8727-421f15a30ee1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_99DB9WrHt",
    "aws_user_pools_web_client_id": "5ej2gn0qf5tcl08su2081stov6",
    "oauth": {}
};


export default awsmobile;
