import React, { useState } from "react";
import { SendBar } from "./SendBar";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import "../Css/TypeBox.css";

export const TypeBox = props => {
  const [message, setMessage] = useState("");

  // When Typing- sets message state to that which is typed...
  const typing = event => {
    setMessage(event.target.value);
  };

  // Send assigns Msg state and sends it to DB....
  const send = async () => {
    // Creates the New Post
    await API.graphql(
      graphqlOperation(mutations.createPost, {
        input: {
          body: `${message}`,
          createdAt: "",
          postConversationId: props.convo,
          createdByUserId: props.user.id,
          convo: props.convo
        }
      })
    );

    //Resets Message form to empty...
    setMessage("");
  };

  const keyPress = e => {
    if (e.key === "Enter") {
      send();
    }
  };

  return (
    <div className="Type-Box">
      {props.auth && (
        <React.Fragment>
          <textarea
            className="txtarea"
            placeholder="type here"
            value={message}
            onKeyPress={keyPress}
            onChange={typing}
          />

          <SendBar send={send} message={message} />
        </React.Fragment>
      )}
    </div>
  );
};
