import React, { useState, useEffect } from "react";
import * as queries from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../graphql/subscriptions";
import "../Css/FriendBox.css";

export const FriendBox = props => {
  const [users, setUsers] = useState([]);
  const [viewSetting, setViewSetting] = useState("Friend-Box");
  const [styleChange, setStyleChange] = useState("hide-friends");
  const [friendStyle, setFriendStyle] = useState("hide-bar");

  // On Render queries for users, and subscribes to User Creation.
  useEffect(() => {
    queryUsers();
    subscriptionUser();
  }, []);

  useEffect(() => {
    friendView();
  }, [props.friendShow]);

  useEffect(() => {
    if (props.auth) {
      setViewSetting("Friend-Box");
      setFriendStyle("");
    } else if (!props.auth) {
      setStyleChange("hide-friends");
      setFriendStyle("hide-bar");
    }
  }, [props]);

  // Queries DB for users.
  const queryUsers = async () => {
    const allUsers = await API.graphql(
      graphqlOperation(queries.listUsers, { limit: 25 })
    );

    // Filters out the current user from Friend List
    let filteredUsers = allUsers.data.listUsers.items.filter(user => {
      return user.name !== localStorage.getItem("userName");
    });
    setUsers(filteredUsers);
  };

  // Subscribes to New User Creation & Adds to user array...
  const subscriptionUser = () => {
    API.graphql(graphqlOperation(subscriptions.onCreateUser)).subscribe({
      next: newUserData => {
        const newUser = newUserData.value.data.onCreateUser;
        setUsers(prevUsers => {
          const updatedUsers = [...prevUsers, newUser];
          return updatedUsers;
        });
      }
    });
  };
  const friendView = () => {
    if (props.friendShow) {
      setViewSetting("Friend-Overlay");
    } else {
      setViewSetting("Friend-Box");
    }
  };
  return (
    <div className={viewSetting + " " + styleChange}>
      <div className={"friend-label " + friendStyle}>Friends Online-</div>
      {props.auth && (
        <div>
          {users.map(user => {
            if (props.selectedUser === user.id) {
              return (
                <div
                  className="usr-btn chosen"
                  onClick={() => props.setConvo(user)}
                  key={user.id}
                  value={user.id}
                >
                  {user.name}
                </div>
              );
            } else {
              return (
                <div
                  className="usr-btn friend"
                  onClick={() => props.setConvo(user)}
                  key={user.id}
                  value={user.id}
                >
                  {user.name}
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
