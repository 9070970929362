import React from "react";
import "../Css/NavBar.css";

const NavBar = props => {
  return (
    <div className="NavBar">
      <div className="svg-icon">
        {" "}
        <i className="fas fa-comments " />
      </div>
      <div className="logo">Chat Zone</div>
      <div className="backtitle" />
      {props.auth && (
        <div className="friend-burger" onClick={props.showFriendsOnPhone}>
          <i className="fas fa-bars" />
        </div>
      )}

      {props.loginMsg && <div className="login-msg">{props.loginMsg}</div>}
      {!props.loginMsg && props.auth && (
        <div className="logout-container">
          <button className="logout-btn" onClick={props.logout}>
            Logout
          </button>
        </div>
      )}
      {!props.render && !props.auth && !props.renderIn && (
        <div className="nav-btns">
          <button
            type="button"
            className="navitem1"
            onClick={props.handleUserSignUp}
          >
            Sign Up
          </button>
          <button
            type="button"
            className="navitem1"
            onClick={props.handleUserSignIn}
          >
            Sign In
          </button>
        </div>
      )}
    </div>
  );
};

export default NavBar;
